<template>
  <div id="action">
    <div id="titolo-aurora" class="row col-8">
      <h2><b><i>Aurora</i></b></h2>
    </div>
    <div id="info-generiche" class="row col-8">
      <ul class="list-group list-group-flush">
        <li class="list-group-item">Premi per aggiornare il giorno della cacca 
          <button type="button" class="btn btn-outline-primary"
                  data-bs-toggle="modal" data-bs-target="#modal" @click="sendName('Aurora')">Aggiorna</button></li>
        <li class="list-group-item"></li>
      </ul>
    </div>
    <div id="titolo-arianna" class="row col-8">
      <h2><b><i>Arianna</i></b></h2>
    </div>
    <div id="info-generiche" class="row col-8">
      <ul class="list-group list-group-flush">
        <li class="list-group-item">Premi per aggiornare il giorno della cacca 
          <button type="button" class="btn btn-outline-primary" 
                  data-bs-toggle="modal" data-bs-target="#modal" @click="sendName('Arianna')">Aggiorna</button></li>
        <li class="list-group-item"></li>
      </ul>
    </div>
  </div>

<!-- Modal -->
<div class="modal fade" tabindex="-1" role="dialog" id="modal" aria-labelledby="modalTitle">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title h5 " id="modalTitle">Sei sicuro di voler aggiornare il giorno della cacca?</h2>
      </div>
      <div class="modal-footer">
        <button class="btn btn btn-primary btn-sm" data-bs-dismiss="modal" type="button"
                @click="dateShit.setData(new Date().toLocaleDateString('it-IT'), baby)">Aggiorna</button>
        <button class="btn btn-danger btn-sm" data-bs-dismiss="modal" type="button">Annulla</button>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import { dateShit } from "../ConstPassing.js"

export default {
  components: {
 },
 data() {
    return {
      dateShit,
      baby: '',
      name: String
    }
  },
  methods: {
    sendName(name) {
      if(name == 'Aurora') {
        this.baby = 'Aurora'
      } else {
        this.baby = 'Arianna'
      }
      
    }
  },
}

</script>

<style>
h2{
  color: plum;
  font-size: 2em;
  padding-left: 5%;
  padding-top: 2%;
  margin-left: 11%;
  margin-top: -7%;
}
#titolo-aurora {
  margin-top: -8%;
  margin-left: 3%;
  text-align: left;
}
#titolo-arianna {
  margin-top: 0%;
  margin-left: 3%;
  text-align: left;
}
#action {
  float:left;
  display:block; 
  width:56%;
  margin-left: -2%;
  margin-top: 4%;
}
</style>