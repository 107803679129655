import { reactive } from 'vue'

export const dateShit = reactive({
  data: null,
  dateViewAurora: localStorage.getItem('dateShitAuroraView'),
  dateViewArianna: localStorage.getItem('dateShitAriannaView'),
  setData(date, baby) {
    this.data = date;
    if(baby == 'Aurora') {
      localStorage.removeItem('dateShitAuroraView')
      localStorage.setItem('dateShitAuroraView', this.data)
      this.dateViewAurora = localStorage.getItem('dateShitAuroraView')
    } else {
      localStorage.removeItem('dateShitAriannaView')
      localStorage.setItem('dateShitAriannaView', this.data)
      this.dateViewArianna = localStorage.getItem('dateShitAriannaView')
    }
  },
})