<template>
  <div id="row">
    <div id="menu">
      <router-link to="/info">Info</router-link>
    </div>
    <div id="menu">
      <router-link to="/action">Azioni</router-link>
    </div>
  </div>
  <!-- <div>
    <router-view></router-view>
  </div> -->
</template>

<script>

</script>

<style>
#row {
  float:left; 
  display:block; 
  width:24%;
  text-align: left;
}
a {
  padding-left: 1em;
  padding-bottom: 0.5em;
  font-size: 1.5em;
}
#menu {
  padding-bottom: 5%;
}
</style>