import { createApp } from 'vue'
import 'bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import 'bootstrap-icons/font/bootstrap-icons.css'

import InfoComponent from './components/InfoComponent.vue'
import ActionComponent from './components/ActionComponent.vue'
import PageNotFoundComponent from './components/PageNotFoundComponent.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: InfoComponent },
        { path: '/info', component: InfoComponent  },
        { path: '/action', component: ActionComponent  },
        { path: '/:pathMatch(.*)*', component: PageNotFoundComponent  }
    ]
});

export const app = createApp(App)

app.use(router);

app.mount('#app')