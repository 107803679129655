<template>
  <div id="info">
    <div id="titolo">
      <h2><b><i>Cacca</i></b></h2>
    </div>
    <div id="info-generiche">
      <ul class="list-group list-group-flush">
        <li class="list-group-item"><i class="bi bi-badge-wc-fill"></i> Aurora ha fatto la cacca il {{ dateShit.dateViewAurora }}</li>
        <li class="list-group-item"><i class="bi bi-badge-wc-fill"></i> Arianna ha fatto la cacca il {{ dateShit.dateViewArianna }}</li>
      </ul>
    </div>
</div>
</template>

<script>
import { dateShit } from "../ConstPassing.js"

export default {
  data() {
    return {
      dateShit
    }
  },
  methods: {
  }
}
</script>

<style>
h2{
  color: plum;
  font-size: 2em;
  padding-left: 5%;
  padding-top: 2%;
  margin-left: 11%;
  margin-top: -7%;
}
#titolo {
  margin-top: -8%;
  margin-left: -4%;
  text-align: left;
}
#info-generiche {
  margin-left: 11%;
  text-align: left;
  font-size: 1.3em;
}
#info {
  float:left;
  display:block; 
  width:68%;
  margin-left: -2%;
  margin-top: 4%;
}
</style>