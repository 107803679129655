<template>
  <h1><b>Cacca Today </b><img src="./assets/cacca.png" alt="title" style="size: 1%;"></h1>
  <LeftMenu />
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import LeftMenu from './components/LeftMenu.vue'

export default {
  name: 'App',
  components: {
    LeftMenu
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
h1{
  font-size: 3em;
  text-align: center;
  padding-bottom: 1em;
  color: steelblue;
}
</style>
